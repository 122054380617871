import React, {Component, Fragment} from 'react';
// import "./TableView.css";
// import "./Menu.css";
import {FormattedDate} from 'react-intl';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import ReactPaginate from 'react-paginate';
import { rest_getDatos } from '../restful';

import { parsePro , parseNice, parseNiceHeaders } from '../json_nodo';

class TableView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      resultadosPaginaConfig: [5, 10, 15, 20],
      resultadosPagina: 5,
      numeroPagina: 1,
      totalDatos: 0,
      datos: [],
      show: false,
      modalDetail: {}
    }

    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handlePaginasChange = this.handlePaginasChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async handleShow(detail) {
    this.setState({ show: true , modalDetail: detail });
  }

  async handleClose() {
    this.setState({ show: false , modalDetail: {} });
  }

  async handlePaginasChange(k) {
    this.setState(() => ({resultadosPagina: k }), await this.handleSearchClick );
  }

  async handlePageClick(data) {
    //console.log(data);
    // let offset = Math.ceil(selected * this.props.perPage);

    // this.setState({ offset: offset }, () => {
    //   this.loadCommentsFromServer();
    //});
    this.setState(() => ({
          numeroPagina: data.selected + 1
        }), await this.handleSearchClick );
  }

  async handleSearchClick() {
    this.setState({ isloading: true });
    let paginado = { pagina: this.state.numeroPagina, resultados: this.state.resultadosPagina }
    //console.log("Condiciones de Busqueda :");
    //console.log(paginado);
    let resultado = await rest_getDatos(this.props.token, this.props.pozoSeleccionado, paginado)
                     .then(result => JSON.parse(result).values )
                      .catch(error => console.log('error', error));
    //console.log(resultado);
    if (resultado.hasOwnProperty("rows")) this.setState({ datos: resultado.rows })
    else this.setState({ datos: [] })
    this.setState({ totalDatos: resultado.count })
    this.setState({ isloading: false })
  }

  decodeNodo(json) {
    return parsePro(json);
  }

  render() {
    return (
      <div className="TableView">
        {!(Object.entries(this.props.pozoSeleccionado).length === 0 && this.props.pozoSeleccionado.constructor === Object) && (
          <Fragment>
            <Form inline className="table-form">
              <Button variant="primary" onClick={this.handleSearchClick} disabled={this.state.isloading} >
              { (this.state.isloading) && <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
              /> }
                { (this.state.isloading) ? 'Cargando...' : 'Buscar Datos'}
              </Button>
              <Form.Label className="table-text">Resultados Pagina</Form.Label>
              <DropdownButton title={this.state.resultadosPagina} id="dropdown-pozos-resultados-pagina" onSelect={this.handlePaginasChange}> 
                {this.state.resultadosPaginaConfig.map(
                          (numero) => <Dropdown.Item key={numero} eventKey={numero}>{numero}</Dropdown.Item>
                )}
              </DropdownButton>
            </Form>
            {(this.state.datos.length > 0 ) && (
              <Fragment>
                {(Math.floor(this.state.totalDatos/this.state.resultadosPagina) > 0) && <div id="react-paginate">
                  <ReactPaginate
                    previousLabel={'Anterior'}
                    nextLabel={'Siguiente'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.floor(this.state.totalDatos/this.state.resultadosPagina)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}

                    forcePage={this.state.numeroPagina-1}
                  />
                </div>}
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Fecha</th>
                      <th>Nodo</th>
                      {/* <th>Payload</th> */}
                      { // Object.keys(this.decodeNodoNice(JSON.parse(this.state.datos[0].payload))).map(
                        [ <>Nivel [m]</>, <>Flujo Instantaneo [m<sup>3</sup>/h]</>, <>Flujo Acumulado [m<sup>3</sup>]</> ].map(
                          (key) =>
                            <th>{key}</th>
                      )}
                      {/* <th>Payload (Decode)</th> */}
                      <th>Energia</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.datos.map(
                      (dato) => {
                        const payload = parseNice(JSON.parse(dato.payload));
                        return (
                        <tr key={dato.id_dato}>
                          <td>{dato.id_dato}</td>
                          {/* <td>{dato.fecha}</td> */}
                          {/* <td>{(new Date(dato.fecha)).toString()}}</td> */}
                          <td>
                            {/* {dato.fecha} */}
                            <FormattedDate 
                            value={new Date(dato.fecha)}
                              day="numeric"
                              month="numeric"
                              year="numeric"
                              hour="2-digit"
                              minute="numeric"
                              second="numeric"
                              timeZoneName="short"
                            />
                          </td>
                          <td>{dato.fk_nodo}</td>
                          { Object.values(payload.main).map(
                            (value) => <td>{value}</td>
                          )}
                          <td>
                            <Button variant={ payload.error.detail ? "warning" : "info" } onClick={ () => this.handleShow(payload.detail) } size="sm">Detalle</Button>
                          </td>
                          {/* <td><pre>{JSON.stringify(this.decodeNodo(JSON.parse(dato.payload)), null, 2)}</pre></td> */}
                        </tr>)
                      }
                    )}
                  </tbody>
                </Table>
                {(Math.floor(this.state.totalDatos/this.state.resultadosPagina) > 0) && <div id="react-paginate">
                  <ReactPaginate
                    previousLabel={'Anterior'}
                    nextLabel={'Siguiente'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.floor(this.state.totalDatos/this.state.resultadosPagina)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}

                    forcePage={this.state.numeroPagina-1}
                  />
                </div>}
              </Fragment>
            )}
          </Fragment>
        )}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Detalles</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th style={{textAlign: 'right'}}>Fase</th>
                  <th>A</th>
                  <th>B</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Voltaje [V]</td>
                  <td>{ this.state.modalDetail.RMSUA }</td>
                  <td>{ this.state.modalDetail.RMSUB }</td>
                  <td>{ this.state.modalDetail.RMSUC }</td>
                </tr>
                <tr>
                  <td>Corriente [A]</td>
                  <td>{ this.state.modalDetail.RMSIA }</td>
                  <td>{ this.state.modalDetail.RMSIB }</td>
                  <td>{ this.state.modalDetail.RMSIC }</td>
                </tr>
                <tr>
                  <td>Potencia [W]</td>
                  <td>{ this.state.modalDetail.PA }</td>
                  <td>{ this.state.modalDetail.PB }</td>
                  <td>{ this.state.modalDetail.PC }</td>
                </tr>
                <tr>
                  <td>FP</td>
                  <td>{ this.state.modalDetail.PFA }</td>
                  <td>{ this.state.modalDetail.PFB }</td>
                  <td>{ this.state.modalDetail.PFC }</td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
            {/* <Button variant="primary" onClick={this.handleClose}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default TableView;