import React, {Component, Fragment, PureComponent} from 'react';
import "./DashboardView.css";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work

import {
  XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  PieChart, Pie, Sector, Cell,
  PolarGrid, PolarAngleAxis, PolarRadiusAxis,
} from 'recharts';
import { ResponsiveContainer,
  LineChart, Line,
  RadarChart, Radar,
  BarChart, Bar,
  AreaChart, Area,
  ScatterChart,Scatter
} from 'recharts';

import { rest_getDatosEstacion } from '../restful';

moment.locale('es');

const getCardinalDirection = (angle) =>{
  const directions = ['↑ N', '↗ NE', '→ E', '↘ SE', '↓ S', '↙ SW', '← W', '↖ NW'];
  return directions[Math.round(angle / 45) % 8];
}

const WindTooltip = ({ active, payload, label }) => {
  if(payload)
    if (active) {
      console.log(payload);
      return (
        <Fragment>
          {/* <div className="custom-tooltip">
            <p className="label">{`Hora:  ${moment(label).format('HH:mm:ss')}`}</p>
            <p className="label" className="label" style={{ color: payload[0].stroke }} >{`Valor: ${payload[0].value} [${payload[0].unit}]`}</p>
            <p className="label" style={{ color: payload[0].stroke }} >{`Grados: ${payload[0].payload.value2}º`}</p>
            <p className="label" style={{ color: payload[0].stroke }} >{`Dirección: ${getCardinalDirection(payload[0].payload.value2)}`}</p>
          </div> */}
          <div className="custom-tooltip-2">
            <Table borderless size="sm">
              <thead>
                <tr>
                  <th>Evento</th>
                  <th>{moment(label).format('HH:mm:ss')}</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ color: payload[0].stroke }}>
                  <td>Valor</td>
                  <td>{`${payload[0].value} [${payload[0].unit}]`}</td>
                </tr>
                <tr style={{ color: payload[0].stroke }}>
                  <td>Grados</td>
                  <td>{`${payload[0].payload.value2}º`}</td>
                </tr>
                <tr style={{ color: payload[0].stroke }}>
                  <td>Dirección</td>
                  <td>{`${getCardinalDirection(payload[0].payload.value2)}`}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Fragment>
      );
    }
  return null;
};

const SimpleCustomTooltip = ({ active, payload, label }) => {
  if(payload)
    if (active) {
      return (
        <Fragment>
          {/* <div className="custom-tooltip">
            <p className="label">{`Evento:  ${moment(label).format('HH:mm:ss')}`}</p>
            {payload.map(
              (dato, i) => <p key={i} className="label" style={{ color: dato.stroke }} >{dato.name}: {dato.value} [{dato.unit}]</p>
            )}
          </div> */}
          <div className="custom-tooltip-2">
            <Table borderless size="sm">
              <thead>
                <tr>
                  <th>Evento</th>
                  <th>{moment(label).format('HH:mm:ss')}</th>
                </tr>
              </thead>
              <tbody>
                {payload.map(
                  (dato, i) =>  <tr style={{ color: dato.stroke }}>
                                  <td>{dato.name}</td>
                                  <td>{dato.value} [{dato.unit}]</td>
                                </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Fragment>
      );
    }
  return null;
};

class DashboardView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      resultadosPagina: 150,
      numeroPagina: 1,
      totalDatos: 0,
      datos: [],
      data: [],
      data2: [],
      data3: [],
      data_wind_speed: [],
      data_wind_direction: [],
      data_wind_speed_direction: [],
      data_temperature: [],
      data_humidity: [],
      data_temperature_humidity: [],
      data_air_pressure: [],
      data_rainfall_per_minute: [],
      data_rainfall_accumulated: [],
      data_solar_intensity: [],
      data_solar_daily: [],
      data_rocio: [],
      periodo: "",
    }

    this.handleRefreshClick = this.handleRefreshClick.bind(this);
  }

  componentDidMount() {
    // this.handleRefreshClick();
  }

  async handleRefreshClick() {
    this.setState({ isloading: true });
    let paginado = { pagina: this.state.numeroPagina, resultados: this.state.resultadosPagina }
    //console.log("Condiciones de Busqueda :");
    //console.log(paginado);
    // https://api.164749894.xyz/api/sesiones/datos-estacion?serial=${estacion.serial_estacion}&resultados=${paginado.resultados}&pagina=${paginado.pagina}
    let resultado = await rest_getDatosEstacion(this.props.token, this.props.estacionSeleccionada, paginado)
                     .then(result => JSON.parse(result).values )
                      .catch(error => console.log('error', error));
    // console.log(resultado);
    if (resultado.hasOwnProperty("rows")){
      // pasar payload a JSON
      let datos = [];
      resultado.rows.forEach(dato => {
        let tmp_dato = dato;
        tmp_dato.fecha = (new Date(dato.fecha)).getTime();
        tmp_dato.hora = moment(tmp_dato.fecha).format('HH:mm:ss');
        tmp_dato.payload = JSON.parse(dato.payload);
        datos.push(tmp_dato);
      });
      // console.log(datos);
      this.setState({ datos: datos });
      // // wind_speed
      // let data_wind_speed = datos.map((dato) => {
      //   return { name: dato.hora, value: dato.payload.wind_speed, time: dato.fecha };
      // });
      // console.log(data_wind_speed);
      // this.setState({ data_wind_speed: data_wind_speed});
      // // wind_direction
      // let data_wind_direction = datos.map((dato) => {
      //   return { name: dato.hora, value: dato.payload.wind_direction, time: dato.fecha };
      // });
      // console.log(data_wind_direction);
      // this.setState({ data_wind_direction: data_wind_direction});
      // wind_speed_direction
      let data_wind_speed_direction = datos.map((dato) => {
        return { value: dato.payload.wind_speed, value2: dato.payload.wind_direction, time: dato.fecha };
      });
      // console.log(data_wind_speed_direction);
      this.setState({ data_wind_speed_direction: data_wind_speed_direction});
      // // temperature
      // let data_temperature = datos.map((dato) => {
      //   return { name: dato.hora, value: dato.payload.temperature, time: dato.fecha };
      // });
      // console.log(data_temperature);
      // this.setState({ data_temperature: data_temperature});
      // // humidity
      // let data_humidity = datos.map((dato) => {
      //   return { name: dato.hora, value: dato.payload.humidity, time: dato.fecha };
      // });
      // console.log(data_humidity);
      // this.setState({ data_humidity: data_humidity});

      // Temperatura de Punto de Rocio
      let data_rocio = datos.map((dato) => {
        let RH = dato.payload.humidity;
        let T = dato.payload.temperature;
        return { name: dato.hora, value: Math.round((243.12 * ((Math.log10(RH)-2)/0.4343 + (17.62*T)/(243.12+T))/(17.62-((Math.log10(RH)-2)/0.4343 + (17.62*T)/(243.12+T))) + Number.EPSILON) * 100) / 100, time: dato.fecha };
      });
      // console.log(data_rocio);
      this.setState({ data_rocio: data_rocio});
      // temperature // humidity
      let data_temperature_humidity = datos.map((dato) => {
        return { name: dato.hora, value: dato.payload.temperature, value2: dato.payload.humidity, time: dato.fecha };
      });
      // console.log(data_temperature_humidity);
      this.setState({ data_temperature_humidity: data_temperature_humidity});
      // air_pressure
      let data_air_pressure = datos.map((dato) => {
        return { name: dato.hora, value: dato.payload.air_pressure, time: dato.fecha };
      });
      // console.log(data_air_pressure);
      this.setState({ data_air_pressure: data_air_pressure});
      // rainfall_per_minute
      let data_rainfall_per_minute = datos.map((dato) => {
        return { name: dato.hora, value: dato.payload.rainfall_per_minute, time: dato.fecha };
      });
      // console.log(data_rainfall_per_minute);
      this.setState({ data_rainfall_per_minute: data_rainfall_per_minute});
      // rainfall_accumulated
      let data_rainfall_accumulated = datos.map((dato) => {
        return { name: dato.hora, value: dato.payload.rainfall_accumulated, time: dato.fecha };
      });
      // console.log(data_rainfall_accumulated);
      this.setState({ data_rainfall_accumulated: data_rainfall_accumulated});
      // solar_intensity
      let data_solar_intensity = datos.map((dato) => {
        return { name: dato.hora, value: dato.payload.solar_intensity, time: dato.fecha };
      });
      // console.log(data_solar_intensity);
      this.setState({ data_solar_intensity: data_solar_intensity});
      // solar_daily
      let data_solar_daily = datos.map((dato) => {
        return { name: dato.hora, value: dato.payload.solar_daily, time: dato.fecha };
      });
      // console.log(data_solar_daily);
      this.setState({ data_solar_daily: data_solar_daily});
      // data: [
      //   { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
      //   { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
      //   { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
      //   { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
      //   { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
      //   { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
      //   { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 }
      // ]
    }
    else this.setState({ datos: [] });
    this.setState({ totalDatos: resultado.count });
    this.setState({ isloading: false });
  }

  render() {
    const date_format_string = 'DD/MM HH:mm ';

    return (
      <div className="DashboardView">
        {!(Object.entries(this.props.estacionSeleccionada).length === 0 && this.props.estacionSeleccionada.constructor === Object) && (
          <Fragment>
            <div className="contenedor-refresh">
              <Button variant="primary" onClick={this.handleRefreshClick} disabled={this.state.isloading} >
                { (this.state.isloading) && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> }
                { (this.state.isloading) ? 'Cargando...' : 'Actualizar'}
              </Button>
            </div>

            <div className="contenedor-opciones-periodo">
              <Container>
                <Row>
                  <Col><h1>Monitoreo Proyecto</h1></Col>
                  <Col>
                    <ToggleButtonGroup type="radio" name="radio" value={this.state.periodo} onChange={(val) => this.setState({ periodo: val })}>
                      <ToggleButton variant="primary" value={"diario"}>Diario</ToggleButton>
                      <ToggleButton variant="primary" value={"semanal"}>Semanal</ToggleButton>
                      <ToggleButton variant="primary" value={"mensual"}>Mensual</ToggleButton>
                      <ToggleButton variant="primary" value={"anual"}>Anual</ToggleButton>
                    </ToggleButtonGroup>

                    <Button variant="primary" onClick={() => this.setState({ periodo: "" })} >
                      Custom
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="contenedor-resumen">
              <Card className="resumen-temperatura">
                <Card.Body>
                  <Card.Title>TEMPERATURA</Card.Title>
                  {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                  <Card.Text>
                  <Table borderless hover>
                    <tbody>
                      <tr>
                        <td>Actual</td>
                        <td>20 [ºC]</td>
                      </tr>
                      <tr>
                        <td>Máxima</td>
                        <td>30 [ºC]</td>
                      </tr>
                      <tr>
                        <td>Mínima</td>
                        <td>10 [ºC]</td>
                      </tr>
                    </tbody>
                  </Table>
                  </Card.Text>
                  {/* <Card.Link href="#">Card Link</Card.Link>
                  <Card.Link href="#">Another Link</Card.Link> */}
                </Card.Body>
              </Card>
            </div>

            <div className="contenedor-resumen">
              <Card className="resumen-humedad">
                <Card.Body>
                  <Card.Title>HUMEDAD</Card.Title>
                  <Card.Text>
                  <Table borderless hover>
                    <tbody>
                      <tr>
                        <td>Actual</td>
                        <td>20 [%]</td>
                      </tr>
                      <tr>
                        <td>Máxima</td>
                        <td>30 [%]</td>
                      </tr>
                      <tr>
                        <td>Mínima</td>
                        <td>10 [%]</td>
                      </tr>
                    </tbody>
                  </Table>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="contenedor-resumen">
              <Card className="resumen-radiacion">
                <Card.Body>
                  <Card.Title>RADIACIÓN SOLAR</Card.Title>
                  <Card.Text>
                  <Table borderless hover>
                    <tbody>
                      <tr>
                        <td>Actual</td>
                        <td>20 [W/m2]</td>
                      </tr>
                      <tr>
                        <td>Acumulada</td>
                        <td>30 [Mj/m2]</td>
                      </tr>
                    </tbody>
                  </Table>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="contenedor-resumen">
              <Card className="resumen-lluvia">
                <Card.Body>
                  <Card.Title>LLUVIA</Card.Title>
                  {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                  <Card.Text>
                  <Table borderless hover>
                    <tbody>
                      <tr>
                        <td>Actual</td>
                        <td>20 [mm]</td>
                      </tr>
                      <tr>
                        <td>Acumulada</td>
                        <td>30 [mm]</td>
                      </tr>
                    </tbody>
                  </Table>
                  </Card.Text>
                  {/* <Card.Link href="#">Card Link</Card.Link>
                  <Card.Link href="#">Another Link</Card.Link> */}
                </Card.Body>
              </Card>
            </div>

            <div className="contenedor-datos">
              {/* wind_speed AND wind_direction */}
              <h2>Velocidad del Viento</h2>
              <ResponsiveContainer width = '95%' height = {500} >
                <LineChart
                  width={500}
                  height={300}
                  data={this.state.data_wind_speed_direction}
                  margin={{
                    top: 20, right: 30, left: 20, bottom: 10,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} /> */}
                  <XAxis
                    dataKey = 'time'
                    domain = {['auto', 'auto']}
                    name = 'Time'
                    tickFormatter = {(nodeDate) => moment(nodeDate).format(date_format_string)}
                    type = 'number'
                  />
                  <YAxis dataKey = 'value' label={{ value: 'Velocidad [m/s]', position: 'insideLeft', angle: -90 , offset: 0 }} />
                  <Tooltip content={<WindTooltip />} />
                  {/* <Legend /> */}
                  <Line dataKey="value" type="monotone" name='Velocidad del Viento'  stroke="#1942B3" unit="m/s" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            {/* <div className="contenedor-datos">
              <h1>Velocidad del Viento</h1>
              <ResponsiveContainer width = '95%' height = {500} >
                <ScatterChart
                  width={400}
                  height={400}
                  data={this.state.data_wind_speed_direction}
                  margin={{
                    top: 20, right: 20, bottom: 20, left: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey = 'time'
                    domain = {['auto', 'auto']}
                    name = 'Time'
                    tickFormatter = {(nodeDate) => moment(nodeDate).format(date_format_string)}
                    type = 'number'
                  />
                  <YAxis dataKey = 'value' label={{ value: 'Velocidad [m/s]', position: 'insideLeft', angle: -90 , offset: 0 }} />
                  <Tooltip content={<WindTooltipScatter unit="m/s" color="#82ca9d" /> } />
                  <Scatter name="Velocidad del Viento" fill="#82ca9d" />
                </ScatterChart>
                
              </ResponsiveContainer>
            </div> */}

            <div className="contenedor-datos">
              {/* temperature AND humidity */}
              <h2>Temperatura y Humedad</h2>
              <ResponsiveContainer width = '95%' height = {500} >
                <LineChart
                  width={500}
                  height={300}
                  data={this.state.data_temperature_humidity}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" /> */}
                  <XAxis
                    dataKey = 'time'
                    domain = {['auto', 'auto']}
                    name = 'Time'
                    tickFormatter = {(nodeDate) => moment(nodeDate).format(date_format_string)}
                    type = 'number'
                  />
                  <YAxis yAxisId="left" orientation="left" label={{ value: 'Temperatura [°C]', position: 'insideLeft', angle: -90 , offset: 0 }}/>
                  <YAxis yAxisId="right" orientation="right" label={{ value: 'Humedad [%]', position: 'insideRight', angle: -90 , offset: 0 }}/>
                  <Tooltip content={<SimpleCustomTooltip />} />
                  {/* <Legend /> */}
                  {/* <Line yAxisId="left" type="monotone" dataKey="value" name='Temperatura' stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                  <Line yAxisId="left" type="monotone" dataKey="value" name='Temperatura' stroke="#1942B3" unit="°C" />
                  <Line yAxisId="right" type="monotone" dataKey="value2" name='Humedad' stroke="#2EC7CF" unit="%" />
                </LineChart>
              </ResponsiveContainer>
            </div>

            <div className="contenedor-datos">
              {/* Temperatura de Punto de Rocío */}
              <h2>Punto de Rocío</h2>
              <ResponsiveContainer width = '95%' height = {500} >
                <LineChart
                  width={500}
                  height={300}
                  data={this.state.data_rocio}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" /> */}
                  <XAxis
                    dataKey = 'time'
                    domain = {['auto', 'auto']}
                    name = 'Time'
                    tickFormatter = {(nodeDate) => moment(nodeDate).format(date_format_string)}
                    type = 'number'
                  />
                  <YAxis yAxisId="left" orientation="left" label={{ value: 'Punto de Rocío [°C]', position: 'insideLeft', angle: -90 , offset: 0 }}/>
                  <Tooltip content={<SimpleCustomTooltip />} />
                  {/* <Legend /> */}
                  {/* <Line yAxisId="left" type="monotone" dataKey="value" name='Temperatura' stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                  <Line yAxisId="left" type="monotone" dataKey="value" name='Temperatura' stroke="#1942B3" unit="°C" />
                  <Line yAxisId="right" type="monotone" dataKey="value2" name='Humedad' stroke="#2EC7CF" unit="%" />
                </LineChart>
              </ResponsiveContainer>
            </div>

            <div className="contenedor-datos">
              {/* air pressure */}
              <h2>Presión del Aire</h2>
              <ResponsiveContainer width = '95%' height = {500} >
                <LineChart
                  width={500}
                  height={300}
                  data={this.state.data_air_pressure}
                  margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" /> */}
                  <XAxis
                    dataKey = 'time'
                    domain = {['auto', 'auto']}
                    name = 'Time'
                    tickFormatter = {(nodeDate) => moment(nodeDate).format(date_format_string)}
                    type = 'number'
                  />
                  {/* <YAxis /> */}
                  <YAxis label={{ value: 'Presión del Aire [hPa]', position: 'insideLeft', angle: -90 , offset: 0 }} 
                    type="number" domain={[900, 1100]} />
                  <Tooltip content={<SimpleCustomTooltip />} />
                  {/* <Legend /> */}
                  {/* <Line type="monotone" dataKey="c" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                  <Line type="monotone" dataKey="value" name='Presión del Aire' stroke="#1942B3" unit="hPa" />
                </LineChart>
              </ResponsiveContainer>
            </div>

            <div className="contenedor-datos">
              {/* rainfall per minute */}
              <h2>Lluvia por Minuto</h2>
              <ResponsiveContainer width = '95%' height = {500} >
                <BarChart
                  width={500}
                  height={300}
                  data={this.state.data_rainfall_per_minute}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" /> */}
                  <XAxis
                    dataKey = 'time'
                    domain = {['auto', 'auto']}
                    name = 'Time'
                    tickFormatter = {(nodeDate) => moment(nodeDate).format(date_format_string)}
                    type = 'number'
                  />
                  <YAxis label={{ value: 'Agua Caída [mm/min]', position: 'insideLeft', angle: -90 , offset: 0 }} />
                  <Tooltip content={<SimpleCustomTooltip />} />
                  {/* <Legend /> */}
                  {/* <Bar dataKey="pv" fill="#8884d8" /> */}
                  <Bar dataKey="value" name='Lluvia por Minuto' fill="#1942B3" unit="mm/min" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="contenedor-datos">
              {/* rainfall_accumulated */}
              <h2>Lluvia Acumulada</h2>
              <ResponsiveContainer width = '95%' height = {500} >
                <BarChart
                  width={500}
                  height={300}
                  data={this.state.data_rainfall_accumulated}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" /> */}
                  <XAxis
                    dataKey = 'time'
                    domain = {['auto', 'auto']}
                    name = 'Time'
                    tickFormatter = {(nodeDate) => moment(nodeDate).format(date_format_string)}
                    type = 'number'
                  />
                  <YAxis label={{ value: 'Agua Caída [mm]', position: 'insideLeft', angle: -90 , offset: 0 }} />
                  <Tooltip content={<SimpleCustomTooltip />} />
                  {/* <Legend /> */}
                  {/* <Bar dataKey="pv" fill="#8884d8" /> */}
                  <Bar dataKey="value" name='Lluvia Acumulada' fill="#1942B3" unit="mm" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            {/* <div>
              rain_landing_state
            <h1>Tipo de Lluvia</h1>
            </div> */}

            <div className="contenedor-datos">
              {/* solar_intensity */}
              <h2>Radiación Solar</h2>
              <ResponsiveContainer width = '95%' height = {500} >
                <AreaChart
                  width={500}
                  height={400}
                  data={this.state.data_solar_intensity}
                  margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" /> */}
                  <XAxis
                    dataKey = 'time'
                    domain = {['auto', 'auto']}
                    name = 'Time'
                    tickFormatter = {(nodeDate) => moment(nodeDate).format(date_format_string)}
                    type = 'number'
                  />
                  <YAxis label={{ value: 'Radiación [W/m2]', position: 'insideLeft', angle: -90 , offset: 10 }} />
                  <Tooltip content={<SimpleCustomTooltip />} />
                  {/* <Legend /> */}
                  <Area type="monotone" dataKey="value" name='Radiación Solar' stroke="#1942B3" fill="#2EC7CF" unit="W/m2" />
                </AreaChart>
              </ResponsiveContainer>
            </div>

            <div className="contenedor-datos">
              {/* solar_daily */}
              <h2>Radiación Solar Acumulada</h2>
              <ResponsiveContainer width = '95%' height = {500} >
                <LineChart
                  width={500}
                  height={300}
                  data={this.state.data_solar_daily}
                  margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" /> */}
                  <XAxis
                    dataKey = 'time'
                    domain = {['auto', 'auto']}
                    name = 'Time'
                    tickFormatter = {(nodeDate) => moment(nodeDate).format(date_format_string)}
                    type = 'number'
                  />
                  <YAxis label={{ value: 'Radiación [MJ/m2]', position: 'insideLeft', angle: -90 , offset: 0 }} />
                  {/* <Tooltip /> */}
                  <Tooltip content={<SimpleCustomTooltip />} />
                  {/* <Legend /> */}
                  {/* <Line type="monotone" dataKey="c" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                  <Line type="monotone" dataKey="value" name='Radiación Solar Acumulada' stroke="#1942B3" unit="MJ/m2" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default DashboardView;