import React, {Component, Fragment} from 'react';
import { slide as Menu } from 'react-burger-menu';
// import "./SideMenuView.css";

import Image from 'react-bootstrap/Image'

class SideMenuView extends React.Component {
  constructor(props) {
    super(props);
    
    this.handleCloseSession = this.handleCloseSession.bind(this);
  }
  
  showSettings (event) {
    event.preventDefault();
  }

  handleCloseSession (event) {
    event.preventDefault();
    this.props.onCloseSesion();
  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Fragment>
        {!(Object.entries(this.props.profile).length === 0 && this.props.profile.constructor === Object) && (
          <Menu pageWrapId={this.props.pageWrapId} outerContainerId={this.props.outerContainerId}>
            <Image src={require('../images/default_profile.png')} roundedCircle />
            <a onClick={ this.showSettings } className="menu-item--small" href="">Inicio</a>
            <a onClick={ this.showSettings } className="menu-item--small" href="">Ver Datos</a>
            <a onClick={ this.showSettings } className="menu-item--small" href="">Perfil</a>
            <a onClick={ this.closeSession } className="menu-item--small" href="">Cerrar Sesión</a>
          </Menu>
        )}
      </Fragment>
    );
  }
}

export default SideMenuView;