import React, {Component, Fragment} from 'react';
import "./TableEstacionesView.css";
// import "./Menu.css";
import {FormattedDate} from 'react-intl';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import ReactPaginate from 'react-paginate';
import { rest_getDatosEstacion } from '../restful';

import { parseEstacionNice } from '../json_nodo';

class TableEstacionesView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      resultadosPaginaConfig: [5, 10, 15, 20],
      resultadosPagina: 5,
      numeroPagina: 1,
      totalDatos: 0,
      datos: [],
      show: false,
      modalDetail: {}
    }

    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handlePaginasChange = this.handlePaginasChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async handleShow(detail) {
    this.setState({ show: true , modalDetail: detail });
  }

  async handleClose() {
    this.setState({ show: false , modalDetail: {} });
  }

  async handlePaginasChange(k) {
    this.setState(() => ({resultadosPagina: k }), await this.handleSearchClick );
  }

  async handlePageClick(data) {
    //console.log(data);
    // let offset = Math.ceil(selected * this.props.perPage);

    // this.setState({ offset: offset }, () => {
    //   this.loadCommentsFromServer();
    //});
    this.setState(() => ({
          numeroPagina: data.selected + 1
        }), await this.handleSearchClick );
  }

  async handleSearchClick() {
    this.setState({ isloading: true });
    let paginado = { pagina: this.state.numeroPagina, resultados: this.state.resultadosPagina }
    //console.log("Condiciones de Busqueda :");
    //console.log(paginado);
    let resultado = await rest_getDatosEstacion(this.props.token, this.props.estacionSeleccionada, paginado)
                     .then(result => JSON.parse(result).values )
                      .catch(error => console.log('error', error));
    console.log(resultado);
    if (resultado.hasOwnProperty("rows")) this.setState({ datos: resultado.rows })
    else this.setState({ datos: [] })
    this.setState({ totalDatos: resultado.count })
    this.setState({ isloading: false })
  }

  render() {
    return (
      <div className="TableView">
        {!(Object.entries(this.props.estacionSeleccionada).length === 0 && this.props.estacionSeleccionada.constructor === Object) && (
          <Fragment>
            <Form inline className="table-form">
              <Button variant="primary" onClick={this.handleSearchClick} disabled={this.state.isloading} >
              { (this.state.isloading) && <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
              /> }
                { (this.state.isloading) ? 'Cargando...' : 'Buscar Datos'}
              </Button>
              <Form.Label className="table-text">Resultados Pagina</Form.Label>
              <DropdownButton title={this.state.resultadosPagina} id="dropdown-pozos-resultados-pagina" onSelect={this.handlePaginasChange}> 
                {this.state.resultadosPaginaConfig.map(
                          (numero) => <Dropdown.Item key={numero} eventKey={numero}>{numero}</Dropdown.Item>
                )}
              </DropdownButton>
            </Form>
            {(this.state.datos.length > 0 ) && (
              <Fragment>
                {(Math.floor(this.state.totalDatos/this.state.resultadosPagina) > 0) && <div id="react-paginate">
                  <ReactPaginate
                    previousLabel={'Anterior'}
                    nextLabel={'Siguiente'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.floor(this.state.totalDatos/this.state.resultadosPagina)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}

                    forcePage={this.state.numeroPagina-1}
                  />
                </div>}
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Fecha</th>
                      <th>Nodo</th>
                      {/* <th>Payload</th> */}
                      { // Object.keys(this.decodeNodoNice(JSON.parse(this.state.datos[0].payload))).map(
                        [ <>Temperatura [°C]</>, <>Humedad [%]</>, <>Velocidad Viento [m/s]</>, <>Estado Lluvia</> ].map(
                          (key) =>
                            <th>{key}</th>
                      )}
                      {/* <th>Payload (Decode)</th> */}
                      {/* <th>Energia</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.datos.map(
                      (dato) => {
                        const payload = parseEstacionNice(JSON.parse(dato.payload));
                        console.log(payload);
                        return (
                        <tr key={dato.id_dato_estacion}>
                          <td>{dato.id_dato_estacion}</td>
                          {/* <td>{dato.fecha}</td> */}
                          {/* <td>{(new Date(dato.fecha)).toString()}}</td> */}
                          <td>
                            {/* {dato.fecha} */}
                            <FormattedDate 
                            value={new Date(dato.fecha)}
                              day="numeric"
                              month="numeric"
                              year="numeric"
                              hour="2-digit"
                              minute="numeric"
                              second="numeric"
                              timeZoneName="short"
                            />
                          </td>
                          <td>{dato.fk_nodo}</td>
                          { [ payload.temperature, payload.humidity, payload.wind_speed, payload.rain_landing_state_decoded ].map(
                            (value) => <td>{value}</td>
                          )}
                          {/* <td>
                            <Button variant={ payload.error.detail ? "warning" : "info" } onClick={ () => this.handleShow(payload.detail) } size="sm">Detalle</Button>
                          </td> */}
                          {/* <td><pre>{JSON.stringify(this.decodeNodo(JSON.parse(dato.payload)), null, 2)}</pre></td> */}
                        </tr>)
                      }
                    )}
                  </tbody>
                </Table>
                {(Math.floor(this.state.totalDatos/this.state.resultadosPagina) > 0) && <div id="react-paginate">
                  <ReactPaginate
                    previousLabel={'Anterior'}
                    nextLabel={'Siguiente'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.floor(this.state.totalDatos/this.state.resultadosPagina)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}

                    forcePage={this.state.numeroPagina-1}
                  />
                </div>}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default TableEstacionesView;